var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myTask"},[_c('div',{staticClass:"head"},_vm._l((_vm.headData),function(item,index){return _c('div',{key:index,staticClass:"headitem",style:([
      {
        backgroundImage:
          _vm.status !== item.status
            ? `url(${item.bgcUrl})`
            : `url(${item.activebgcUrl})`,
      },
      { color: _vm.status === item.status ? '#fff' : '#000' },
      { marginRight: index === _vm.headData.length - 1 ? '0px' : '10px' },
    ]),on:{"click":function($event){return _vm.changeStatus(item.status)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(_vm.numberData[item.fieId] ? _vm.numberData[item.fieId] : 0))])])}),0),(_vm.itemData.length > 0)?_c('div',{staticClass:"body"},_vm._l((_vm.itemData),function(item,index){return _c('user_mar_item',{key:index,staticClass:"item",attrs:{"task-data":item}})}),1):_c('el-empty',{staticStyle:{"flex":"1"},attrs:{"description":"暂无数据"}}),_c('el-pagination',{staticStyle:{"margin-left":"AUTO","margin-top":"10px","display":"block"},attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"pager-count":7,"small":"","current-page":_vm.pageNo,"page-size":_vm.pageSize},on:{"current-change":_vm.userTask,"update:currentPage":function($event){_vm.pageNo=$event},"update:current-page":function($event){_vm.pageNo=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }