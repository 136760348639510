import { render, staticRenderFns } from "./user_mar_item.vue?vue&type=template&id=5094fd47&scoped=true&"
import script from "./user_mar_item.vue?vue&type=script&lang=js&"
export * from "./user_mar_item.vue?vue&type=script&lang=js&"
import style0 from "./user_mar_item.vue?vue&type=style&index=0&id=5094fd47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5094fd47",
  null
  
)

export default component.exports