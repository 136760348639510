<!--
 * @Author: dingguowei
 * @Date: 2023-06-14 10:02:46
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-05 15:38:08
-->
<!-- 我的任务单个任务组件 -->
<template>
  <div>
    <el-card class="hallitem">
      <div class="title">
        <p style="font-size: 0.9rem; font-weight: 600">{{ taskData.title }}</p>
        <p style="font-size: 0.6rem; color: #999999">
          截止时间:{{ taskData.endAt }}
        </p>
      </div>
      <div class="ask">
        <p style="font-size: 0.8rem; font-weight: 600">任务要求</p>
        <div class="askbody">
          <p>提交任务立得 <span>{{ taskData.downPayment }}</span>元，提交后满 <span>{{ taskData.finalDays }}</span>天再奖励<span>{{
            taskData.finalPayment }}</span>元
          </p>
          <p>互动数满 <span>{{ taskData.salaryListVos[0].totalNum }}</span>奖励 <span>{{
            taskData.salaryListVos[0].salary }}</span></p>
        </div>
      </div>
      <div class="num">
        <span :style="{ color: textColor(taskData.userStatus) }">{{
          statusText(taskData.userStatus)
        }}</span>

        <el-button round size="mini" style="color: #fff" :style="{
              backgroundColor:
                taskData.userStatus === 'CLOSED' ? '#999999' : '#5874EF',
            }" @click="receive(taskData)">
          查看详情
        </el-button>
      </div>
    </el-card>
  </div>
</template>
    
<script>
import { userclose } from "@/api/user";
import router from "@/router";
import store from "@/store";
export default {
  props: {
    taskData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      askSetting: [
        {
          name: "互动数",
          fieID: "totalNum",
        },
        {
          name: "点赞数",
          fieID: "likeNum",
        },
        {
          name: "收藏数",
          fieID: "collectNum",
        },
        {
          name: "评论数",
          fieID: "commentNum",
        },
        {
          name: "转发数",
          fieID: "shareNum",
        },
      ],
    };
  },
  created() { },
  mounted() { },
  watch: {},
  computed: {
    statusText() {
      return function (val) {
        if (val === "TOJUDGE") {
          return "待审核";
        } else if (val === "CLOSED") {
          return "已关闭";
        } else if (val === "FINISHED") {
          return "通过";
        } else if (val === "REFUSED") {
          return "拒绝";
        } else if (val === "FORBID") {
          return "拉黑";
        } else if (val === "TOFINISH") {
          return "进行中";
        }
      };
    },
    textColor() {
      return function (val) {
        if (val === 1) {
          return "#cb2dcd";
        } else if (val === 2) {
          return "#999999";
        } else if (val === 3) {
          return "#7ce546";
        } else if (val === 4) {
          return "#e18b32";
        } else if (val === 5) {
          return "#c92626";
        } else if (val === 0) {
          return "#5874EF";
        }
      };
    },
  },
  methods: {
    receive(val) {
      store.commit("task/CHANGE_publisherAccount", val.publisherAccount);
      store.commit("task/CHANGE_publisherId", val.publisherId);
      store.commit("task/CHANGE_MISSIONID", val.id);
      store.commit("user/CHANGE_userCurTaskStatus", val.userStatus);
      store.commit("task/CHANGE_missionReceiveId", val.missionReceiveId);
      router.push({ path: "/concretecontent" });
    },
    closeTask(val) {
      var that = this;
      userclose({
        missionReceiveId: val.missionReceiveId,
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "成功关闭任务",
            type: "success",
          });
          that.$emit("closeTask");
        } else {
          this.$message.error("关闭任务失败");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.hallitem {
  font-family: PingFangSC-Regular, PingFang SC;

  .title {
    line-height: 160%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ask {
    margin-top: 10px;

    .askbody {
      padding: 7px 0;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      font-size: 0.7rem;

      & span {
        color: red;
        font-weight: bold;
      }
    }
  }

  .num {
    margin-top: 10px;
    display: flex;

    & span {
      //   padding: 6px 10px;
      display: inline-block;
      flex: 1;
      // width: 14rem;
      font-size: 1rem;
      font-weight: 600;
      //   background-color: #f6f6f6;
      //   border-radius: 1rem;
    }
  }
}
</style>